import { gql } from '@apollo/client';

export const UserFields = gql`
  fragment UserFields on User {
    id
    role
    lastName
    firstName
    slug
    email
    phoneNumber
    isOnboarded
    isEmailSet
    onboardingSteps
    cartSize
    status
    hasPassword
    hashtags {
      id
      name
    }
    storeDetails {
      id
      athleticLevel
      hasActedOnAutoGeneratedMerch
      storeName
      storeTitle
      bio
      careerStatus
      avatarURL
      socialMediaLink
      description
      coverURL
      facebookLink
      twitterLink
      instagramLink
      tiktokLink
      youtubeLink
      facebookFollowing
      twitterFollowing
      tiktokFollowing
      instagramFollowing
      youtubeFollowing
      birthDate
      gender
    }
    sports {
      id
      name
    }
  }
`;

export const AuthorizedUserFields = gql`
  fragment AuthorizedUserFields on AuthorizedUser {
    id
    email
    accessToken
    refreshToken
    role
  }
`;
